<style scoped>
    .left-container.flex {flex-basis: 350px;max-width: 350px;}
    .right-container.flex {flex-basis: calc(100% - 350px);max-width: calc(100% - 350px);}
    .v-application .caption{color: #929292!important;}
    .instrument{border: 1px solid #e9e9e9;background: #FCFCFC;}
    .instrument:nth-child(1){margin-top:16px !important;}
    .instrument .tysmdata{color:#5E5E5E}
    .misclr {color: #e2ad0d;background-color: #fdf7ef;padding: 2px 8px;font-size: 10px;letter-spacing: 1px;border-radius: 3px; vertical-align: text-bottom;}
    .nrmlclr {color: #5845ff;background-color: #e6e3ff;padding: 2px 8px;font-size: 10px;letter-spacing: 1px;border-radius: 3px; vertical-align: text-bottom;}
    .qtyclr {color: #2ea6f5 !important;}
    /* .negativevalue {color: #00b894 !important;}
    .positivevalue {color: #d63031 !important;} */
    .theme--dark .instrument{background: #272727;border: 1px solid #363636;}
    .theme--dark .instrument .tysmdata{color: #2ea6f5;}
    .theme--dark .misclr{background-color: #1e1e1e;}
    .v-application.theme--dark .c-b-white{background-color: #1e1e1e;}   
</style>
<style>
.calculatedHeight{height: calc(100vh - 54px);}
</style>

<template>
  <div>
    <v-container fluid grid-list-xl>
      <v-layout row wrap align-start justify-start class="c-main">
        <v-flex fixed xs12 sm12 md6 lg4 xl4 max-width="fixed" class="left-container c-scroll pa-2 c-b-white">
          <v-card class="a-box pa-0 mx-auto mt-4" width="fit-content">
            <span class="grey">
              <v-tabs centered class="horizontal-tab tabsegment c-b-gray" height="36">
                <v-tab height="28" color="#ffffff">Position</v-tab>
                <!-- <v-tab height="28" color="#ffffff">Group</v-tab> -->
              </v-tabs>
            </span>
          </v-card>
          <div class="mt-7">
            <v-flex>
              <v-row class="ma-0">
                <v-col class="text-left pa-0">
                  <div class="caption f-light">Total decay</div>
                  <div class="f-semi-18-12"></div>
                </v-col>
                <v-col class="text-center pa-0">
                  <div class="caption f-light">Unused Margin</div>
                  <div class="f-semi-18-12 ml-2 mt-1 text-left">{{ funds.net }}</div>
                </v-col>
                <v-col class="text-right pa-0">
                  <div class="caption f-light">Total P&L</div>
                  <div class="f-semi-18-12 ml-2 mt-1" >{{  }}</div>
                </v-col>
              </v-row>
            </v-flex>
            <v-flex wrap class="mt-7 pt-0">
              <div class="mt-2">
                <div class="caption f-light"> Showing <span class="ml-2 subtitle-4">
                    <label class="c-f-primary f-regular" >
                        Futures & Options 
                    </label>
                  </span>
                </div>
              </div>
              <div>
              <v-row class="ma-0 mt-2 py-4 instrument rounded" v-for="(group, index) in positionGroupedData" :key="index">
                <v-checkbox :ripple="false" class="ml-3 align-self-center"></v-checkbox>
                <div class="ml-2 align-self-center f-semi-14-12 tysmdata">{{  index }}
                  <span class="ml-1 f-light" >{{  }}</span>
                </div>
                <v-col class="text-center align-self-center">
                  <div class="text-right mr-3">
                    {{  }}
                  </div>
                </v-col>
              </v-row>
              </div>
            </v-flex>
          </div>
        </v-flex>
        <v-flex xs12 sm12 md6 lg8 xl8 class="right-container c-scroll px-2 py-2" >
          <div>
          <v-card class="a-box ma-2 ml-0 mr-0 mt-0" v-for="(group, index) in positionGroupedData" :key="index">
              <v-flex>
                <v-row>
                  <v-card-title class="subtitle-1 pa-0 ml-7 mt-5">
                    <span class="f-semi-18-12" >{{ index }}</span>
                    <span class="caption f-light ml-2" :class="group.percentage >= 0 ? 'negativevalue' : 'positivevalue'">{{ group.percentage }}</span>
                  </v-card-title>
                  <div>
                    <span class="ml-6">
                      <img class="light-th mt-6" src="@/assets/images/icons/Graph.svg" />
                      <img class="dark-th mt-6" src="@/assets/images/icons/graph-d.svg" />
                    </span>
                    <span class="ml-4">
                      <img class="light-th mt-6" src="@/assets/images/icons/Info.svg" />
                      <img class="dark-th mt-6" src="@/assets/images/icons/info-d.svg" />
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <div>
                    <v-menu max-width="250" :left="true" class="pa-3" offset-y transition="slide-y-transition" open-on-hover rounded>
                      <template v-slot:activator="{ on, attrs }">
                        <label class="mr-7 mt-6 float-right caption pa-0" v-bind="attrs" v-on="on"> 
                            Show more
                            <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="" fill="#929292"
                                style="position:relative;top:3px; height: 14px; width: 14px;">
                              <path  d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                            </svg>
                            <span class="align-center align-content-start"></span>
                        </label>
                      </template>
                      <v-list>
                        <div class="pa-2" v-for="(s, i) in items" :key="i">
                          <p class="caption f-light"> Delta <span class="float-right ml-8">{{ s.delta}}</span></p>
                          <p class="caption f-light"> Gamma <span class="float-right ml-8">{{ s.gamma}}</span></p>
                          <p class="caption f-light"> Vega <span class="float-right ml-8">{{ s.vega}}</span></p>
                        </div>
                      </v-list>
                    </v-menu>
                  </div>
                </v-row>
              </v-flex>
              <!-- <v-flex class="pt-0" width="fit-content">
              </v-flex> -->
              <v-flex>
                <v-row>
                  <v-col class="pa-0 ml-7 mt-3 mb-3">
                    <v-btn text-lowercase x-small  class="ml-0 pa-2 text-capitalize mb-0" outlined color="#2EA6F5">Show all</v-btn>
                    <span class="ml-5">
                      <v-btn x-small  class="ml-0 pa-2 text-capitalize" outlined color="#7E7E7E" style="border-color: #E1E1E1;">02 Sep</v-btn>
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
              <v-flex class="pt-0">
                <v-row class="ma-0">
                  <v-col class="pa-0">
                    <v-simple-table dense class="pa-4">
                      <template v-slot:default>
                        <thead>
                          <tr class="pa-3">
                            <th class="text-left pa-0" width="20">
                              <v-checkbox :ripple="false"></v-checkbox>
                            </th>
                            <th class="text-center pa-0 caption f-light">Type</th>
                            <th class="pa-0 caption f-light">Name</th>
                            <th class="text-right pa-0 caption f-light">Qty</th>
                            <th class="text-right pa-0 caption f-light">Avg</th>
                            <th class="text-right pa-0 caption f-light">LTP</th>
                            <th class="text-right pa-0 caption f-light">P/L</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="pa-0" height="0" v-for="(item,index) in group" :key="index">
                            <td class="text-left pa-0">
                              <v-checkbox :ripple="false"></v-checkbox>
                            </td>
                            <td class="text-center pa-0">
                             <span :class="item.Pcode == 'MIS' ? 'misclr' : 'nrmlclr'">{{ item.Pcode }}</span>
                            </td>
                            <td class="pa-0 text-left" rounded>
                              <span class=" subtitle">{{ item.Tsym }}</span>
                              <span class=" Body-2" color="#5e5e5e">{{ item.Exchange}}</span>
                            </td>
                            <td class="pa-0 text-right subtitle" :class="item.Netqty == '159'? 'qtyclr': item.Netqty == '189'? 'qtyclr': item.Netqty == '345'? 'qtyclr': ''"> {{ item.Netqty }} </td>
                            <td class="pa-0 text-right subtitle"> {{ item.Buyavgprc }} </td>
                            <td class="pa-0 text-right subtitle"> {{ item.LTP }} </td>
                            <td class="pa-0 text-right subtitle" :class="item.MtoM >= 0? 'positivevalue': 'negativevalue'"> {{ item.MtoM }} </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th class="text-left pa-0" colspan="6">Total P&L</th>
                            <td class="pa-0 text-right subtitle" > {{  }} </td>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-flex>
          </v-card>
          </div>
          <div class="d-flex justify-center align-center no-data" v-if="positionGroupedData.length == 0">
            NO DATA FOUND
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapState ,mapGetters} from "vuex"
export default {
  name: "positionsPage",
  data() {
    return {
      // positionlist: [{ totalpnl: "-1912", unusedmargin: "6,578", totaldecay: "-2,789",},],
      // positiondata: [{ datavalue: 1.4, tysmdata: "NIFTY 45890.45", data: -52, positionvalue: 1,},{ datavalue: -0.3, tysmdata: "BANK NIFTY 75890.45", data: -139, positionvalue: 3,},],
      // positionGroupedData :[
      //   {
      //     "BANKNIFTY":[
      //       {"realisedprofitloss":"-2,372.50","Fillsellamt":"8,752.50","Netqty":"0","Symbol":"BANKNIFTY","Instname":"OPTIDX","Expdate":"28 Oct, 2021","BLQty":25,"Opttype":"CE","LTP":"860.10","Token":"42023","Fillbuyamt":"11,125.00","Fillsellqty":"25","Tsym":"BANKNIFTY28OCT2140700CE","sSqrflg":"Y","unrealisedprofitloss":"0.00","Buyavgprc":"445.00","MtoM":"-2,372.50","stat":"Ok","s_NetQtyPosConv":"Y","Sqty":"25","Sellavgprc":"350.10","PriceDenomenator":"1","PriceNumerator":"1","actid":"TNC230","posflag":"true","Bqty":"25","Stikeprc":"40700.0","Pcode":"MIS","BEP":"0.00","Exchange":"NFO","GeneralDenomenator":"1","Series":"XX","Type":"DAY1","Netamt":"-2,372.50","companyname":"","Fillbuyqty":"25","GeneralNumerator":"1","Exchangeseg":"nse_fo","discQty":"NA"},
      //       {"realisedprofitloss":"0.00","Fillsellamt":"8,525.00","Netqty":"-25","Symbol":"BANKNIFTY","Instname":"OPTIDX","Expdate":"28 Oct, 2021","BLQty":25,"Opttype":"PE","LTP":"172.25","Token":"42024","Fillbuyamt":"0.00","Fillsellqty":"25","Tsym":"BANKNIFTY28OCT2140700PE","sSqrflg":"Y","unrealisedprofitloss":"4,218.75","Buyavgprc":"0.00","MtoM":"4,218.75","stat":"Ok","s_NetQtyPosConv":"Y","Sqty":"25","Sellavgprc":"341.00","PriceDenomenator":"1","PriceNumerator":"1","actid":"TNC230","posflag":"true","Bqty":"0","Stikeprc":"40700.0","Pcode":"MIS","BEP":"341.00","Exchange":"NFO","GeneralDenomenator":"1","Series":"XX","Type":"DAY1","Netamt":"8,525.00","companyname":"","Fillbuyqty":"0","GeneralNumerator":"1","Exchangeseg":"nse_fo","discQty":"NA"}
      //       ]
      //     }, 
      //     {"NIFTY":[
      //   {"realisedprofitloss":"-2,372.50","Fillsellamt":"8,752.50","Netqty":"0","Symbol":"BANKNIFTY","Instname":"OPTIDX","Expdate":"28 Oct, 2021","BLQty":25,"Opttype":"CE","LTP":"860.10","Token":"42023","Fillbuyamt":"11,125.00","Fillsellqty":"25","Tsym":"BANKNIFTY28OCT2140700CE","sSqrflg":"Y","unrealisedprofitloss":"0.00","Buyavgprc":"445.00","MtoM":"-2,372.50","stat":"Ok","s_NetQtyPosConv":"Y","Sqty":"25","Sellavgprc":"350.10","PriceDenomenator":"1","PriceNumerator":"1","actid":"TNC230","posflag":"true","Bqty":"25","Stikeprc":"40700.0","Pcode":"MIS","BEP":"0.00","Exchange":"NFO","GeneralDenomenator":"1","Series":"XX","Type":"DAY1","Netamt":"-2,372.50","companyname":"","Fillbuyqty":"25","GeneralNumerator":"1","Exchangeseg":"nse_fo","discQty":"NA"},
      // {"realisedprofitloss":"0.00","Fillsellamt":"8,525.00","Netqty":"-25","Symbol":"BANKNIFTY","Instname":"OPTIDX","Expdate":"28 Oct, 2021","BLQty":25,"Opttype":"PE","LTP":"172.25","Token":"42024","Fillbuyamt":"0.00","Fillsellqty":"25","Tsym":"BANKNIFTY28OCT2140700PE","sSqrflg":"Y","unrealisedprofitloss":"4,218.75","Buyavgprc":"0.00","MtoM":"4,218.75","stat":"Ok","s_NetQtyPosConv":"Y","Sqty":"25","Sellavgprc":"341.00","PriceDenomenator":"1","PriceNumerator":"1","actid":"TNC230","posflag":"true","Bqty":"0","Stikeprc":"40700.0","Pcode":"MIS","BEP":"341.00","Exchange":"NFO","GeneralDenomenator":"1","Series":"XX","Type":"DAY1","Netamt":"8,525.00","companyname":"","Fillbuyqty":"0","GeneralNumerator":"1","Exchangeseg":"nse_fo","discQty":"NA"}
      // ]}],
      items: [{ delta: "11(0.2 FUT)", gamma: "0.097", vega: "144" }],
    };
  },

    created() {
      this.$store.dispatch('positions/fetchPositionBook');
    },

    computed: {
      ...mapState('positions',['funds','positionGroupedData','positionBook']),

      fundsdata() {
        return this.$store.state.funds
      }
    },

    mounted() {
     this.$store.dispatch('positions/getFundsData');
    }

};
</script>
